import React, { Component, Fragment } from 'react';
import { Typography, Card, Row, Col, Button, message, Modal, Form, Input } from 'antd';
import { getUserPets, getUserImage, updatePet } from '../../../api/pets-api';
import PetSelectionCard from '../pet_selection_cards';


const { Title } = Typography;


class PetForClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPets: [],
            spinning: false,
            selectedPetId: 0,
            visible:false
        };

    }

    async componentDidMount() {
        await this.getPets();
        this.props.populateClaimObj('petId', this.state.userPets[this.state.selectedPetId]?.id);
    }

    changePetIndex = (i) => {
        this.setState({ selectedPetId: i });
        this.props.populateClaimObj('petId', this.state.userPets[i].id);
        console.log('selected pet', this.state.userPets[i]);
    }

    handleCancel = e => {
        this.setState({
            visible: false
        });
    };

    onFinish = (values) => {
        this.setState({ spinning: true }, async () => {
            console.log('values', values);
            
            let key = Object.keys(values);
            await updatePet({ id: this.state.userPets[this.state.selectedPetId].id, ...values });
            message.success(`Microchip info updated successfully.`);
            this.props.actionNext();
            //this.updateText(key[0], values[key[0]]);
        })
    }

    checkIfMicroChipAvailable(){
        console.log(this.state.userPets[this.state.selectedPetId].microchip);
        let microchip = this.state.userPets[this.state.selectedPetId].microchip;
        //this.props.actionNext();
        if(microchip == null || microchip == ''){
            this.setState({visible:true})
        } else {
            this.props.actionNext();
        }
    }

    getPets = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let userPets = await getUserPets();
                console.log('userPets', userPets);
                let userPets2 = await Promise.all(userPets.data.result.map(async (item) => {
                    try{
                        await getUserImage(item.image).then((data) => {
                            item.image = data.data.url;
                        })
                    } catch(ex) {
                        item.image = (item.type === 'Dog' ? "/images/banner-dog.png" : "/images/banner-cat.png");
                    }
                }));
                this.setState({ userPets: userPets.data.result }, () => {
                    resolve(userPets.data.result)
                })
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    };

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">Which pet buddy is this claim for?</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <PetSelectionCard userPets={this.state.userPets} changePetIndex={this.changePetIndex} />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev()}>
                                        <i className="fa fa-angle-up"></i>
                                    </Button>
                                </Col>
                                <Col xl={2} lg={4} md={3} sm={3}>
                                <Modal
                                        centered
                                        title={`Please enter microchip number for ${this.state.userPets[this.state.selectedPetId]?.name}`} 
                                        visible={this.state.visible}
                                        onCancel={this.handleCancel}
                                        footer={false}
                                    >
                                        <Form size="large" name="normal_login" className="customise-form"
                                            onFinish={this.onFinish}
                                        >
                                            <Form.Item
                                                name="microchip"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter microchip!',
                                                    },
                                                ]}
                                                >
                                                <Input
                                                    type="text"
                                                    placeholder="Microchip"
                                                />
                                            </Form.Item>
                                            <Form.Item className="mb-0">
                                                <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.checkIfMicroChipAvailable()}>
                                        <i className="fa fa-angle-down"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">Easy, one pet at a time!</span>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default PetForClaim;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Layout, Typography, Card, Row, Col, Upload, Spin, Modal, Form, Input, Button, DatePicker, Select, Radio, Space, Collapse } from 'antd';
import {PlusCircleFilled, PlusOutlined} from '@ant-design/icons'
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import SideMenu from '../pet_cloud/sidebar_menu_card'
import PetSelectionCard from '../pet_cloud/pet_selection_cards'
import { InlineInputEdit } from 'react-inline-input-edit';
import { getUserPets, updatePet, uploadPetImage, getUserImage, getPetBreeds, SavePolicyCancelRequest, SavePolicyChangeRequest } from '../../api/pets-api';
import { upperCase, calculateAge, notifications } from '../../helpers/utilities';
import moment from 'moment';
import MaskedInput from 'antd-mask-input'

const { Option } = Select;
const { Paragraph, Title } = Typography;

const { Content } = Layout;
const { TextArea } = Input;
const { Panel } = Collapse;

class MyPets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPets: [],
            spinning: false,
            spin: false,
            visible: false,
            selectedPetId: 0,
            editField: '',
            selectedText: '',
            visibleAddPet: false,
            editAddPet: false,
            selectedPetIndexAddPet: 0,
            disableBtn: true,
            petTypeBreeds: [],
            petBreeds: [],
            type: '',
            typeName: '',
            dogId: "81d11a99-8756-4dc7-828d-e1d190a2ad06",
            visibleCancelPolicy: false,
            visibleChangePolicy: false,
            visibleAddPreExistingCondition: false,
            preExistingConditions: 
                [
                    
                ]
              
        };
        this.formRef = React.createRef();
        this.cancelPolicyFormRef = React.createRef();
        this.changePolicyFormRef = React.createRef();
        this.addPreExistingConditionFormRef = React.createRef();
        this.changeType = this.changeType.bind(this);
        this.checkType = this.checkType.bind(this);
        this.onPetFormFailed = this.onPetFormFailed.bind(this);
    }
    async componentDidMount() {
        await this.getPets();
        let resp = await getPetBreeds();
        let petTypeBreeds = resp.data.result;
        this.setState({petTypeBreeds})

        petTypeBreeds.map(type => {
            if (type.name == 'Cat') {
                console.log('dogId', type.id)
                this.setState({ dogId: type.id, petBreeds: type.breeds })
            }
        });

        // getUserPets().then((userPets) => {
        //     console.log('userPets',userPets);
        //     this.setState({ userPets: userPets.data.result })
        // })
    }

    changePetIndex = (i) => {
        this.setState({ selectedPetId: i });
    }

    updateText = (field, text) => {
        let userPets = this.state.userPets;
        userPets[this.state.selectedPetId][field] = text;
        this.setState({ userPets, visible: false, spinning: false })
    }

    activityFocusOut = (text) => {
        if(text.length < 14){
            updatePet({ id: this.state.userPets[this.state.selectedPetId].id, favoriteActivity: text });
            this.updateText('favoriteActivity', text);
            console.log('Left editor with text: ' + text);
        }
        else{
            notifications('Text should be less than 14 characters', 'Favorite Activity', 'error');
            return false;
        }
    };

    tagFocusOut = (text) => {
        updatePet({ id: this.state.userPets[this.state.selectedPetId].id, tag: text });
        this.updateText('tag', text);
        console.log('Left editor with text: ' + text);
    };

    foodFocusOut = (text) => {
        if(text.length < 14){
            updatePet({ id: this.state.userPets[this.state.selectedPetId].id, favoriteFood: text });
            this.updateText('favoriteFood', text);
            console.log('Left editor with text: ' + text);
        }
        else{
            notifications('Text should be less than 14 characters', 'Favorite Food', 'error');
            return false;
        }
    };

    wordFocusOut = (text) => {
        if(text.length < 14){
            updatePet({ id: this.state.userPets[this.state.selectedPetId].id, inOneWord: text });
            this.updateText('inOneWord', text);
            console.log('Left editor with text: ' + text);
        }
        else{
            notifications('Text should be less than 14 characters', 'Favorite Food', 'error');
            return false;
        }
    };

    getPets = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let userPets = await getUserPets();
                console.log(userPets)
                let userPets2 = await Promise.all(userPets.data.result.map(async (item) => {
                    try{
                        await getUserImage(item.image).then((data) => {
                            item.image = data.data.url;
                        })
                    } catch(ex) {
                        item.image = (item.type === 'Dog' ? "/images/banner-dog.png" : "/images/banner-cat.png");
                    }
                }));
                console.log('userpet2',userPets2);
                this.setState({ userPets: userPets.data.result }, () => {
                    console.log('userPets', this.state.userPets);
                    resolve(userPets.data.result)
                })
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    };

    uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
        };

        this.setState({ spinning: true })
        fmData.append("image", file);
        console.log('addfile',typeof file);
        
        try {
            const res = await uploadPetImage(this.state.userPets[this.state.selectedPetId].id, fmData, config)
            onSuccess("Ok");
            notifications(res.data.message, 'Uploads')
            await this.getPets();
            this.setState({ spinning: false }, () => {
                // window.location.href = window.location.href;
            });

        } catch (err) {
            console.log("Eroor: ", err);
            onError({ err });
            this.setState({ spinning: false })
            notifications('Something went wrong', 'Uploads', 'error')
        }
    };

    onFinish = (values) => {
        this.setState({ spinning: true }, async () => {
            console.log('values', values);
            
            let key = Object.keys(values);
            await updatePet({ id: this.state.userPets[this.state.selectedPetId].id, ...values });
            this.updateText(key[0], values[key[0]]);
        })
    }

    handleCancel = e => {
        this.setState({
            visible: false
        });
    };

    handleCancelAddPet = e => {
        this.setState({
            visibleAddPet: false,
            editAddPet: false,
            selectedPetIndexAddPet: 0
        });
    }

    handleCloseCancelPolicy = e => {
        this.cancelPolicyFormRef.current.resetFields()
        this.setState({
            visibleCancelPolicy: false
        });
    }
    
    showModelCancelPolicy = e => {
        this.setState({
            visibleCancelPolicy: true
        });
    }

    submitCancelPolicy = async (e) => {
        
        let reason = e.reason;
        let petId = this.state.userPets[this.state.selectedPetId].id;
        let userId = this.state.userPets[this.state.selectedPetId].userId;
        // let petId = 'a9b46e7a-3c58-4ea6-9d31-90b9fddb70ad';
        // let userId = '300a05f8-5bed-4d41-9232-c5f995fe40e4';
        let requ = {
            petId:petId,
            userId: userId,
            reason:reason
        }
        this.setState({
           
            visibleCancelPolicy: false
        });

        await SavePolicyCancelRequest(requ).then((data) => {
            
        })

        this.cancelPolicyFormRef.current.resetFields()
        notifications('Policy Cancellation Requested Successfully', 'Policy Cancellation')
    }

    handleChangeCancelPolicy = e => {
        this.changePolicyFormRef.current.resetFields()
        this.setState({
            visibleChangePolicy: false
        });
    }
    
    showModelChangePolicy = e => {
        this.setState({
            visibleChangePolicy: true
        });
    }

    submitChangePolicy = async (e) => {
        
        let reason = e.reason;
        let petId = this.state.userPets[this.state.selectedPetId].id;
        let userId = this.state.userPets[this.state.selectedPetId].userId;
        // let petId = '8e39ba98-ab00-4734-a6f0-566fd79bd4f6';
        // let userId = '06e1aa64-e589-4633-b058-b26e7e45eec7';
        let requ = {
            petId:petId,
            userId: userId,
            reason:reason
        }

        this.setState({
           
            visibleChangePolicy: false
        });

        await SavePolicyChangeRequest(requ).then((data) => {
            
        })
        this.changePolicyFormRef.current.resetFields()
        notifications('Policy Change Requested Successfully', 'Policy Change')
    }
    
    handleClosePreExistingCondition = e => {
        this.addPreExistingConditionFormRef.current.resetFields()
        this.setState({
            visibleAddPreExistingCondition: false
        });
    }

    showPreExistingCondition = e => {
        this.setState({
            visibleAddPreExistingCondition: true
        });
    }

    submitPreExistingCondition = async (e) => {
        
        let condition = e.condition;
        let date = e.date;
        let comment = e.comment;

        let preExistingConditions = this.state.preExistingConditions;

        preExistingConditions.push({ name:'test', condition, date, comment });
        this.setState({ preExistingConditions });


        this.addPreExistingConditionFormRef.current.resetFields()
        this.setState({
            visibleAddPreExistingCondition: false
        });
        notifications('Pre Existing Conditions Saved Successfully', 'Pre Existing Conditions');
    }

    onPreExistingConditionFailed = (e) => {
    }

    showModalAddPet = () => {
        // (this.formRef && this.formRef.current) ? this.formRef.current.resetFields() : console.log("empty")
        // console.log("this", this.formRef)
        // this.setState({
        //     visibleAddPet: true,
        //     editAddPet: false
        // });
        window.location.pathname = '/add-new-pet'
    }

    onCancelPolicyFailed = () => {
        
    }

    addPetForm = () => {

    }

    onPetFormFailed = () => {

    }

    changeType(event) {
        let index = event.target.id;
        let type = event.target.petType;
        let petBreeds = this.state.petTypeBreeds[index].Breads;
        let typeName = this.state.petTypeBreeds[index].name;
        this.setState({ petBreeds, type, typeName }, () => { if (this.formRef.current.getFieldValue('age')) this.ageChange() })
    }

    checkType(petType) {
        let img = ''
        this.props.petTypeBreeds.map(type => {
            if (type.id == petType) {
                if (type.name == 'Dog') img = "/images/icon-dog-sm.png"
                else img = "/images/icon-cat-sm.png"
            }
        })
        return img;
    }

    ageChange = () => {
        this.setState({ disableBtn: false }, () => {



            let selectedDate = this.formRef.current.getFieldValue('age');
            let type = this.state.typeName;
            let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
            let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
            console.log('age different parameter', ageMthDiff, ageYrDiff, type);
            if (ageMthDiff < 2) {
                let dateCopy = moment(selectedDate);
                let nextDate = dateCopy.add(2, 'M');
                console.log(ageMthDiff, nextDate);
                let ageMsg = 'At Oslo we love puppies and kitten, the effective date of your insurance will be 15th of ' + nextDate.format('MM') + '/' + nextDate.format('Y') + ' , for more info on age requirements please check our FAQ'
                notifications(ageMsg, 'Alert', 'warning')
            }
            
            if (ageMthDiff > 96 && (type === 'Dog' || type === '4bed113e-bf4f-4a64-ba64-5fc0ab563a05')) {
                let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            } else if (ageMthDiff > 120 && type === 'Cat' || type === '81d11a99-8756-4dc7-828d-e1d190a2ad06') {
                let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            }
        });
    }

    editClick = (e,edit) => {
        this.setState({editField: edit, visible: true})
    }

    wrapText = (str) => {
        return str.length > 150 ? str.substring(0, 150)+'...' : str;
    }

    render() {

        const _handleFocus = (text) => {
            this.setState({selectedText:text});
            console.log('Focused with text: ' + text);
        };

        const text = (
            <p
              style={{
                paddingLeft: 24,
              }}
            >
              A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found
              as a welcome guest in many households across the world.
            </p>
          );

        return (
            
            <Layout className="dashboard middle my-pets">
                <LoginHeader />
                <Spin className="spinner-wrp" spinning={this.state.spinning} tip="Loading..." size="large" />
                <Content className="pt-5 pb-5">
                    <SideMenu />
                    <div className="container">
                        <Row gutter={15}>
                            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                <div className="my-pet-detail">
                                    <figure>
                                        {/* <img src="/images/thumbnail-bob-lg.png" /> */}
                                        <Upload
                                            className="petimage"
                                            showUploadList={false}
                                            customRequest={this.uploadImage}
                                        // onClick={() => this.setPetID(pet.id)}
                                        // style={{ display: 'inline-flex' }}
                                        >
                                            <div className={(this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].image)?"pet-image image-uploaded":"pet-image"}>
                                                {
                                                    this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].image &&
                                                    <img alt={this.state.userPets[this.state.selectedPetId].name} src={this.state.userPets[this.state.selectedPetId].image} />
                                                }
                                                {
                                                    this.state.userPets[this.state.selectedPetId] && !this.state.userPets[this.state.selectedPetId].image &&
                                                    <img alt={this.state.userPets[this.state.selectedPetId].name} src={this.state.userPets[this.state.selectedPetId].type === 'Dog' ? "/images/banner-dog.png" : "/images/banner-cat.png"} />
                                                }
                                            </div>
                                        </Upload>
                                        <img className="arrow-img" src="/images/arrow-text.png" />
                                    </figure>
                                    <div className="text">
                                        <Row gutter={[15, 15]} justify="space-between">
                                            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                            <Title level={3} className="text-green font-700 mt-0 mb-4">{upperCase(this.state.userPets[this.state.selectedPetId]?.name || '')}</Title>
                                            </Col> 
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                                                <Button.Group className="mb-4" style={{ float: 'right' }}>
                                                    <Button type="primary" className="bg-red" onClick={this.showModelCancelPolicy} danger>
                                                        Cancel the Policy
                                                    </Button>
                                                    <Button type="primary" className="bg-green" onClick={this.showModelChangePolicy}>
                                                        Change Coverage
                                                    </Button>
                                                </Button.Group>
                                                <div>
                                                    <Modal
                                                        title={<div className="text-dark-green font-600">Cancel the Policy</div>}
                                                        visible={this.state.visibleCancelPolicy}
                                                        footer={false}
                                                        closable={false}
                                                        onCancel={this.handleCloseCancelPolicy}
                                                    >
                                                        <Form
                                                            name="basic"
                                                            onFinish={this.submitCancelPolicy}
                                                            onFinishFailed={this.onCancelPolicyFailed}
                                                            size={"large"}
                                                            className="customise-form"
                                                            ref={this.cancelPolicyFormRef}
                                                        >
                                                            <Form.Item
                                                                name="reason"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input reason!',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea placeholder={"Reason for cancellation *"} />
                                                            </Form.Item>

                                                            
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item className="mb-0 text-center">
                                                                    <Button className="text-muted btn-150" type="link" onClick={this.handleCloseCancelPolicy} size="large">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button type="primary" htmlType="submit" size="large" className="btn-outline-light-green btn-150 rounded-100">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Form>
                                                    </Modal>
                                                </div>
                                                <div>
                                                    <Modal
                                                        title={<div className="text-dark-green font-600">Change the Coverage</div>}
                                                        visible={this.state.visibleChangePolicy}
                                                        footer={false}
                                                        closable={false}
                                                        onCancel={this.handleCloseChangePolicy}
                                                    >
                                                        <Form
                                                            name="basic"
                                                            onFinish={this.submitChangePolicy}
                                                            onFinishFailed={this.onChangePolicyFailed}
                                                            size={"large"}
                                                            className="customise-form"
                                                            ref={this.changePolicyFormRef}
                                                        >
                                                            <Form.Item
                                                                name="reason"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input reason!',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea placeholder={"Requested package *"} />
                                                            </Form.Item>

                                                            
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item className="mb-0 text-center">
                                                                    <Button className="text-muted btn-150" type="link" onClick={this.handleChangeCancelPolicy} size="large">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button type="primary" htmlType="submit" size="large" className="btn-outline-light-green btn-150 rounded-100">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Form>
                                                    </Modal>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Card className="mb-4">

                                            <Title onClick={(e)=>this.editClick(e,'description')} level={5} className="text-green custom-edit-black">{ this.wrapText((this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].description) ? this.state.userPets[this.state.selectedPetId].description : `Hello, I’m ${upperCase(this.state.userPets[this.state.selectedPetId]?.name || '')}! I have a little white dot on my left ear. I love children and I don’t usually bark (just at the big trash cans sometimes).`) }</Title>
                                            <Card className="mt-3 text-center bg-gray-light custom-edit-black microchip" onClick={(e)=>this.editClick(e,'chip')} style={{cursor:'pointer'}}>
                                                <span className="text-light-green font-700 h4">Microchip:</span>
                                                <span className="text-red h4"> {this.state.userPets[this.state.selectedPetId]?.microchip || 'N/A'}</span>
                                            </Card>
                                        </Card>
                                        <Row gutter={[15, 15]} justify="center">
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <div className="pet-characteristics bg-gray-light">
                                                    <Title className="text-red mt-0 mb-0" level={4}>{calculateAge(this.state.userPets[this.state.selectedPetId]?.dob) || '<1'}</Title>
                                                    <Title className="text-green mt-0 mb-0" level={4}>y.o.</Title>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <div className="pet-characteristics bg-gray-light" onClick={(e)=>this.editClick(e,'neutered')} style={{cursor:'pointer'}}>
                                                    <Title className="text-red mt-0 mb-0" level={4}>
                                                        {/* <img src="/images/icon-gender.png" /> */}
                                                        {(this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].neutered) ? 'Yes' : 'No'}
                                                    </Title>
                                                    <Title className="text-green mt-0 mb-0" level={4}>Neutered</Title>
                                                </div>
                                            </Col>
                                            {/* <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <div className="pet-characteristics bg-gray-light" onClick={(e)=>this.editClick(e,'gender')} style={{cursor:'pointer'}}>
                                                    <Title className="text-red mt-0 mb-0" level={4}>
                                                        <img src="/images/icon-gender.png" />
                                                    </Title>
                                                    <Title className="text-green mt-0 mb-0" level={4}>{this.state.userPets[this.state.selectedPetId]?.gender}</Title>
                                                </div>
                                            </Col> */}
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <div className="pet-characteristics bg-gray-light" onClick={(e)=>this.editClick(e,'weight')} style={{cursor:'pointer'}}>
                                                    <Title className="text-red mt-0 mb-0" level={4}>{this.state.userPets[this.state.selectedPetId]?.weight || 'N/A'}</Title>
                                                    <Title className="text-green mt-0 mb-0" level={4}>kg</Title>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <div className="pet-characteristics bg-gray-light" onClick={(e)=>this.editClick(e,'color')} style={{cursor:'pointer'}}>
                                                    <Title className="text-red mt-0 mb-0" level={4}>
                                                        {upperCase(this.state.userPets[this.state.selectedPetId]?.color || 'N/A')}
                                                        {/* <span className="color-circle"></span> */}
                                                    </Title>
                                                    <Title className="text-green mt-0 mb-0" level={4}>color</Title>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row gutter={[15, 15]} justify="space-between" className='mt-5'> 
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Space align="center">
                                                    <Title className="text-red mt-0 mb-0" level={3}>Pre Existing Conditions</Title>
                                                    <Button type="primary" shape="circle" className="bg-green" icon={<PlusOutlined />} size={'large'} onClick={this.showPreExistingCondition} />
                                                </Space>
                                            </Col>
                                            {
                                                this.state.preExistingConditions && 
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Collapse>
                                                        {
                                                            this.state.preExistingConditions.map((condition, index) =>
                                                                <Panel header={ <Title className="text-green mt-0 mb-0" level={4}>{condition.condition}</Title>} key={index}>
                                                                    <Space align="center">
                                                                        <Title className="text-green mt-0 mb-0" level={5}>Date of Diagnosis:</Title>
                                                                        <Title className=" mt-0 mb-0" level={5}>{condition.date}</Title>
                                                                    </Space>
                                                                    <Title className="text-green mt-1 mb-0" level={5}>What Happen:</Title>
                                                                    <Paragraph>{condition.comment}</Paragraph>
                                                                </Panel>
                                                            )
                                                        }
                                                    </Collapse>
                                                </Col>
                                            }
                                            <div>
                                                    <Modal
                                                        title={<div className="text-dark-green font-600">Add Pre Existing Condition</div>}
                                                        visible={this.state.visibleAddPreExistingCondition}
                                                        footer={false}
                                                        closable={false}
                                                        onCancel={this.handleClosePreExistingCondition}
                                                    >
                                                        <Form
                                                            name="basic"
                                                            onFinish={this.submitPreExistingCondition}
                                                            onFinishFailed={this.onPreExistingConditionFailed}
                                                            size={"large"}
                                                            className="customise-form"
                                                            ref={this.addPreExistingConditionFormRef}
                                                        >
                                                            <Form.Item
                                                                name="condition"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please mention condition!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder={"Condition *"} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="date"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select date of diagnosed!',
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker placeholder={"Diagnosed Date *"} className='full-width' />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="comment"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please mention what happen!',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea placeholder={"What happen *"} />
                                                            </Form.Item>

                                                            
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item className="mb-0 text-center">
                                                                    <Button className="text-muted btn-150" type="link" onClick={this.handleClosePreExistingCondition} size="large">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button type="primary" htmlType="submit" size="large" className="btn-outline-light-green btn-150 rounded-100">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Form>
                                                    </Modal>
                                                </div>
                                        </Row> */}

                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <PetSelectionCard userPets={this.state.userPets} changePetIndex={this.changePetIndex} />
                                <Button className='addapet text-green full-width text-center' size={'large'} onClick={this.showModalAddPet}>
                                    <PlusCircleFilled size={'large'} /> <br/>
                                    Add a Pet
                                </Button>
                                <div>
                                <Modal
                                            title={<div className="text-dark-green font-600">Add a Pet</div>}
                                            visible={this.state.visibleAddPet}
                                            footer={false}
                                            closable={false}
                                            onCancel={this.handleCancelAddPet}
                                        >
                                            <Form
                                                name="basic"
                                                onFinish={this.addPetForm}
                                                onFinishFailed={this.onPetFormFailed}
                                                size={"large"}
                                                className="customise-form"
                                                ref={this.formRef}
                                            >
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"Pet’s Name *"} />
                                                </Form.Item>

                                                <Row gutter={15} justify="center">
                                                    <Col sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            className="text-center"
                                                            name="type"
                                                            initialValue={this.state.dogId}
                                                        >
                                                            <Spin spinning={this.state.spin}>
                                                                {
                                                                    this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" onChange={this.changeType}>
                                                                        {
                                                                            this.state.petTypeBreeds.map((type, index) =>
                                                                                <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                            )
                                                                        }
                                                                        {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                    </Radio.Group>
                                                                }
                                                            </Spin>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item className="text-center" name="gender" initialValue={"Male"}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select gender!',
                                                                },
                                                            ]}>
                                                            <Radio.Group buttonStyle="solid">
                                                                <Radio.Button value="Male">Male</Radio.Button>
                                                                <Radio.Button value="Female">Female</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    name="age"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select DOB (MM/YYYY)*!',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Please select DOB (MM/YYYY)!" format="MM/YYYY" picker="month" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="breed"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select breed!',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select Breed"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {
                                                            this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                <Option key={index} value={breed.id}>{breed.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item className="mb-0 text-center">
                                                        <Button className="text-muted btn-150" type="link" onClick={this.handleCancelAddPet} size="large">
                                                            Cancel
                                                        </Button>
                                                        <Button disabled={this.state.disableBtn} type="primary" htmlType="submit" size="large" className="btn-outline-light-green btn-150 rounded-100">
                                                            Add
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Form>
                                        </Modal>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[15, 15]}>
                            <Modal
                                centered
                                title="Pet Edit"
                                visible={this.state.visible}
                                onCancel={this.handleCancel}
                                footer={false}
                            >
                                <Form size="large" name="normal_login" className="customise-form"
                                    onFinish={this.onFinish}
                                    initialValues={{
                                        dob: (this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].dob) ? moment(this.state.userPets[this.state.selectedPetId].dob) : '',
                                        gender: this.state.userPets[this.state.selectedPetId]?.gender,
                                        weight: this.state.userPets[this.state.selectedPetId]?.weight,
                                        microchip: this.state.userPets[this.state.selectedPetId]?.microchip,
                                        color: this.state.userPets[this.state.selectedPetId]?.color,
                                        neutered: this.state.userPets[this.state.selectedPetId]?.neutered,
                                        tag: this.state.userPets[this.state.selectedPetId]?.tag,
                                        description: (this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].description) ? this.state.userPets[this.state.selectedPetId].description : `Hello, I’m ${upperCase(this.state.userPets[this.state.selectedPetId]?.name || '')}! I have a little white dot on my left ear. I love children and I don’t usually bark (just at the big trash cans sometimes).`,
                                        joined: (this.state.userPets[this.state.selectedPetId] && this.state.userPets[this.state.selectedPetId].joined) ? moment(this.state.userPets[this.state.selectedPetId].joined) : '',
                                    }}
                                >
                                    {
                                        this.state.editField == 'dob' && <Form.Item
                                        name="dob"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter DOB!',
                                            },
                                        ]}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="Select DOB! *" format="MM/YYYY" picker="month" />
                                        
                                        {/* <Input placeholder="DOB" /> */}
                                    </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'joined' && <Form.Item
                                            name="joined"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter Joined date!',
                                                },
                                            ]}
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="Select date of adoption! *" format="MM/YYYY" picker="month" />
                                            {/* <Input placeholder="Joined date" /> */}
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'gender' && <Form.Item
                                            name="gender"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter Gender!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select gender *" >
                                                <Option value="Male">Male</Option>
                                                <Option value="Female">Female</Option>
                                                <Option value="Transgender">Transgender</Option>
                                                <Option value="Undisclosed">Undisclosed</Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'weight' && <Form.Item
                                            name="weight"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter weight!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="number"
                                                placeholder="Weight"
                                            />
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'chip' && <Form.Item
                                        name="microchip"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter microchip!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Microchip"
                                        />
                                    </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'color' && <Form.Item
                                            name="color"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter color!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select color *" >
                                                <Option value="Black">Black</Option>
                                                <Option value="White">White</Option>
                                                <Option value="Grey">Grey</Option>
                                                <Option value="Other">Other</Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'neutered' && <Form.Item
                                            name="neutered"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter neutered!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Is he/she neutered *">
                                                <Option value={true}>Yes</Option>
                                                <Option value={false}>No</Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'tag' && <Form.Item
                                            name="tag"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter tag!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder={"OSLO-**-****"}
                                                mask="OSLO-AA-1111"
                                                onChange={this.idChange}
                                            />
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'description' && <Form.Item
                                            name="description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter Pet Introduction!',
                                                },
                                            ]}
                                        >
                                            <TextArea maxLength={250} rows={4} />
                                            
                                        </Form.Item>
                                    }
                                    {
                                        this.state.editField == 'description' && "Max: 250 characters" 
                                    }
                                    <Form.Item className="mb-0">
                                        <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                            Update
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                {
                                    this.state.userPets[this.state.selectedPetId] && <Card style={{cursor:'pointer'}} className="custom-shadow light-green text-center" 
                                    title={
                                        // <InlineInputEdit
                                        //     inputClassName="inputEditable text-white"
                                        //     labelClassName="labelInputEditable text-white"
                                        //     text={this.state.userPets[this.state.selectedPetId].tag || }
                                        //     inputWidth="160px"
                                        //     inputHeight="28px"
                                        //     inputMaxLength={50}
                                        //     labelFontWeight="bold"
                                        //     inputFontWeight="bold"
                                        //     onFocus={_handleFocus}
                                        //     onFocusOut={this.tagFocusOut}
                                        // />
                                        <Title level={4} className="text-center text-white custom-edit">{this.state.userPets[this.state.selectedPetId]?.tag || 'N/A'} </Title>
                                        
                                    }
                                    onClick={(e)=>this.editClick(e,'tag')}
                                >
                                    <img className="mt-3" src="/images/icon-tag-green.png" />
                                    <Title level={4} className="text-green font-700 mt-4">Oslo Tag</Title>
                                </Card>
                                }
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                <Card style={{cursor:'pointer'}} onClick={(e)=>this.editClick(e,'dob')} className="custom-shadow light-green text-center" title={<Title level={4} className="text-center text-white custom-edit">{moment(this.state.userPets[this.state.selectedPetId]?.dob).format('MM/YYYY')}</Title>}>
                                    <img className="mt-3" src="/images/icon-bd-cake.png" />
                                    <Title level={4} className="text-green font-700 mt-4">Birthday</Title>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                <Card style={{cursor:'pointer'}} onClick={(e)=>this.editClick(e,'joined')} className="custom-shadow light-green text-center" title={<Title level={4} className="text-center text-white custom-edit">{(this.state.userPets[this.state.selectedPetId]?.joined && moment(this.state.userPets[this.state.selectedPetId]?.joined).format('YYYY/MM')) || 'N/A'}</Title>}>
                                    <img className="mt-3" src="/images/icon-home-md.png" />
                                    <Title level={4} className="text-green font-700 mt-4">Joined the family</Title>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                {
                                    this.state.userPets[this.state.selectedPetId] && <Card className="custom-shadow light-green text-center"
                                        title={
                                            <InlineInputEdit
                                                inputClassName="inputEditable text-white"
                                                labelClassName="labelInputEditable text-white"
                                                text={this.state.userPets[this.state.selectedPetId]?.favoriteActivity}
                                                inputWidth="160px"
                                                inputHeight="28px"
                                                inputMaxLength="13"
                                                labelFontWeight="bold"
                                                inputFontWeight="bold"
                                                onFocus={_handleFocus}
                                                onFocusOut={this.activityFocusOut}
                                            />
                                        }
                                    >
                                        <img className="mt-3" src="/images/icon-fav-activity.png" />
                                        <Title level={4} className="text-green font-700 mt-4">Favourite activity</Title>
                                    </Card>

                                }
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                {
                                    this.state.userPets[this.state.selectedPetId] && <Card className="custom-shadow light-green text-center"
                                    title={this.state.userPets &&
                                        <InlineInputEdit
                                            inputClassName="inputEditable text-white"
                                            labelClassName="labelInputEditable text-white"
                                            text={this.state.userPets[this.state.selectedPetId]?.favoriteFood}
                                            inputWidth="160px"
                                            inputHeight="28px"
                                            inputMaxLength="13"
                                            labelFontWeight="bold"
                                            inputFontWeight="bold"
                                            onFocus={_handleFocus}
                                            onFocusOut={this.foodFocusOut}
                                        />
                                    }
                                >
                                    <img className="mt-3" src="/images/icon-fav-food.png" />
                                    <Title level={4} className="text-green font-700 mt-4">Favourite food</Title>
                                </Card>
                                }
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                {
                                    this.state.userPets[this.state.selectedPetId] && <Card className="custom-shadow light-green text-center"
                                        title={this.state.userPets &&
                                            <InlineInputEdit
                                                inputClassName="inputEditable text-white"
                                                labelClassName="labelInputEditable text-white"
                                                text={this.state.userPets[this.state.selectedPetId]?.inOneWord}
                                                inputWidth="160px"
                                                inputHeight="28px"
                                                inputMaxLength="13"
                                                labelFontWeight="bold"
                                                inputFontWeight="bold"
                                                onFocus={_handleFocus}
                                                onFocusOut={this.wordFocusOut}
                                            />
                                        }
                                    >
                                        <img className="mt-3" src="/images/icon-one-word.png" />
                                        <Title level={4} className="text-green font-700 mt-4">S.he in one word</Title>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </div>
                </Content>
                <LoginFooter />
            </Layout>
        );
    }
}

export default MyPets;
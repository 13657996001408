import React, { Component } from 'react';
import { Typography, Card, Row, Col, Button, Input } from 'antd';


const { Paragraph, Title } = Typography;
const { TextArea } = Input;


class AddComments extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        // console.log('Change:', e.target.value);
        this.props.populateClaimObj('comments', e.target.value );
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">{this.props.value === 'accident' ? 'Something more to tell us':'Please, describe the circumstances under which your pet buddy went missing or last seen!'}</Title>
                    <Row gutter={[15, 20]} justify="center">
                        <Col xl={12} lg={20} md={24} sm={24} xs={24}>
                            <TextArea className="add-comments mb-5" placeholder={this.props.value === 'accident' ? "Add a comment (250 words max but only if you wish!)" : "500 words max"} showCount maxLength={this.props.value === 'accident'? 250 : 500} onChange={this.onChange} />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={5} lg={10} md={5} sm={5} xs={24}>
                                    <Button block size="large" className="btn-red-outline border-radius-10" onClick={() => this.props.actionNext()}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">Thanks mate!</span>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default AddComments;